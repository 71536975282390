import React from 'react';

interface IPrivacyLink {
    children: React.ReactNode;
    className?: string;
}

export const PrivacyLink: React.FC<IPrivacyLink> = ({ className, children }) => (
    <a
        className={className}
        style={{ textDecoration: 'underline', color: 'inherit' }}
        href="https://www.cosee.biz/datenschutz"
        target="_blank"
        rel="noopener noreferrer nofollow"
    >
        {children}
    </a>
);
